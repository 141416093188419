import { connect } from 'react-redux';
import { IRootState } from '../../shared/store/IRootState';
import {
  // ConnectedMailboxSSSGAApprovalProps,
  MailboxSSSGAApprovalMappedProps,
  MailboxSSSGAApprovalActions
} from './MailboxSSSGAApproval.types';
import { MailboxSSSGAApproval } from './MailboxSSSGAApproval';
import {
  completeListApprvalMailboxSSSRequestsState,
  ensureSystemAdmin,
  getListMailboxSSSRequests,
  initListApprvalMailboxSSSRequestsState,
  userEmailApproval
} from '../../shared/store/actions';
import { IListApprvalMailboxSSSRequestsState } from '../../shared/models/MailboxSSS/IListApprvalMailboxSSSRequestsState';
import { IMailboxSSSRequest } from '../../shared/models/MailboxSSS/IMailboxSSSRequest';

// Reducer States
const mapStateToProps = (
  state: IRootState
  // _ownProps: ConnectedMailboxSSSGAApprovalProps
): MailboxSSSGAApprovalMappedProps => {
  const { lstMailboxSSSRequestsState, lstApprvalMailboxSSSRequestsState } = state;
  return {
    listMailboxSSSRequestsState: lstMailboxSSSRequestsState,
    listApprvalMailboxSSSRequestsState: lstApprvalMailboxSSSRequestsState
  };
};

// Actions
const mapDispatchToProps = (): MailboxSSSGAApprovalActions => {
  return {
    getListMailboxSSSRequests: (query: string) => getListMailboxSSSRequests(query),
    ensureSystemAdmin: (curRequest: number, request: IMailboxSSSRequest) => ensureSystemAdmin(curRequest, request),
    initListApprvalMailboxSSSRequestsState: (stateApproval: IListApprvalMailboxSSSRequestsState) =>
      initListApprvalMailboxSSSRequestsState(stateApproval),
    completeListApprvalMailboxSSSRequestsState: () => completeListApprvalMailboxSSSRequestsState(),
    userEmailApproval: (
      isApproved: boolean,
      businessJustification: string,
      curRequest: number,
      request: IMailboxSSSRequest
    ) => userEmailApproval(isApproved, businessJustification, curRequest, request)
  };
};

export const ConnectedMailboxSSSGAApproval = connect(mapStateToProps, mapDispatchToProps())(MailboxSSSGAApproval);
