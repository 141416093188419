import React, { useEffect } from 'react'
import { Stack } from '@fluentui/react'
import { CoherencePageSize, CoherencePagination, ICoherencePageSizeProps, ICoherencePaginationProps } from '@coherence-design-system/controls'
import { useDispatch, useSelector } from 'react-redux';
import { GRID, SCREEN_SIZE } from '../../shared/constants'
import { MailboxSSSGAApprovalWrapperProps } from './MailboxSSSGAApproval.types';
import { getListMailboxSSSRequests } from '../../shared/store/actions';
import { IRootState } from '../../shared/store/IRootState';

export const MailboxSSSGAApprovalPagination: React.FC<MailboxSSSGAApprovalWrapperProps> = (props) => {
    
    const dispatch = useDispatch();
    const lstMailboxSSSRequestsState = useSelector(
      (state: IRootState) => state.lstMailboxSSSRequestsState
    );

    const paginationProps: ICoherencePaginationProps = {
        pageCount: Math.ceil(props.totalMailboxSSSRequestsCount / props.totalItemsPerPage),
        selectedPage: props.currentPage,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange : (
          startItemIndex: number,
          endItemIndex: number,
          selectedPage: number | undefined
        ) => {
          if (selectedPage !== undefined) {
            props.setCurrentPage(selectedPage);
          }
        }
      };

      const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: props.totalItemsPerPage,
        pageSizeList: GRID.MAILBOXSSS_GA_APPROVAL_PAGINATION.PAGE_SIZE_LIST,
        comboBoxAriaLabel: GRID.MAILBOXSSS_GA_APPROVAL_PAGINATION.ARIA_LABEL,
        onPageSizeChange: (_totalItem) => {
          // eslint-disable-next-line radix
          props.setTotalItemsPerPage(parseInt(_totalItem.toString()));
        }
      };

      useEffect(() => {
        let urlParams=`top=${props.totalItemsPerPage}&skip=${(props.currentPage-1) * props.totalItemsPerPage}`;
        dispatch(getListMailboxSSSRequests(urlParams));
      }, [props.currentPage, props.totalItemsPerPage, dispatch]);

      useEffect(() => {
        if(props.currentPage * props.totalItemsPerPage >= props.totalMailboxSSSRequestsCount) {
          props.setCurrentPage(1);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.totalItemsPerPage, props.totalMailboxSSSRequestsCount]);

      useEffect(() => {
        props.setTotalMailboxSSSRequestsCount(lstMailboxSSSRequestsState.lstMailboxSSSRequests?.totalRecords);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [lstMailboxSSSRequestsState]);

  return (
    <>
    <Stack>
        <Stack horizontalAlign="center" tokens={{ padding: 10, childrenGap: 10 }}>
            <Stack
                horizontal
                horizontalAlign="center"
                {...(window.innerWidth > SCREEN_SIZE.MEDIUM ? { horizontal: true } : {})}
            >
                <CoherencePagination {...paginationProps} />
                <CoherencePageSize {...paginationPageSizeProps} />
            </Stack>
        </Stack>
    </Stack>
</>

  )
}

export default MailboxSSSGAApprovalPagination